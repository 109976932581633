(function($) {
  Drupal.behaviors.basic_infinite_carousel = {
    attach: function($this) {
      $('.basic_infinite_carousel_v1').fadeIn(500);
      $('.main-content', $this).once(function() {
        /*
         * --------------------------------------------------------------------------
         * CONTINUOUS CAROUSEL V1.5
         * --------------------------------------------------------------------------
         * Functionality for a Continuous Carousel with the following features:
         * Allows for the addition of infinite slides.
         * Updates the contents of dummy slides accordingly.
         * Adds identifying classes to each slide for ease of reference.
         * Adds an active class to each slide for ease of visibility.
         */

        $('.basic_infinite_carousel_v1').once().each(function() {
          var $this = $(this);
          var pagCount, interval, resetTimer;
          /*
          /--------------------------------------------------------------------------
          / Strip whitespace to prevent spaces between slides.
          /--------------------------------------------------------------------------
          */
          var stripWhiteSpace = $this.find('.basic_infinite_carouselContainer').find('*');
          stripWhiteSpace.contents().filter(function() {
            return this.nodeType = Node.TEXT_NODE && /\S/.test(this.nodeValue) === false;
          }).remove();

          /*
          /--------------------------------------------------------------------------
          / Remove any content block that renders empty content
          /--------------------------------------------------------------------------
          / Normally due to mismatched revision tags.
          */
          var carouselWrapper = $this.find('.basic_infinite_carouselWrapper');
          var innerContent = carouselWrapper.find('.basic_infinite_carouselInner');
          var checkContent = innerContent.find('.node-elc-nodeblock .content');
          checkContent.each(function() {
            if ($(this).html().length === 0) {
              $(this).parent().addClass('empty_content').parent().detach().insertBefore($this);
            }
          });

          /*
          /--------------------------------------------------------------------------
          / Grab all the content and wrap in slides.
          /--------------------------------------------------------------------------
          */
          $this.find('.basic_infinite_carouselInner').children().wrap('<div class="basic_infinite_carousel_slide"></div>');

          /*
          /--------------------------------------------------------------------------
          / Calculate the number of slides.
          /--------------------------------------------------------------------------
          */
          var slideTotal = innerContent.find('.basic_infinite_carousel_slide').length;
          var slideTotalPerc = (slideTotal * 100) + '%';
          $this.find('.basic_infinite_carouselWrapper').css('width', slideTotalPerc);

          /*
          /--------------------------------------------------------------------------
          / Calculate the width of each slide according to no. of slides.
          /--------------------------------------------------------------------------
          */
          var slideWidth = (100 / slideTotal) + '%';
          $this.find('.basic_infinite_carousel_slide').css('width', slideWidth);

          if ($this.find('.basic_infinite_carouselInner').children('.basic_infinite_carousel_slide').length > 1) {
            /*
            /--------------------------------------------------------------------------
            / Handle pagination dots.
            /--------------------------------------------------------------------------
            */
            var paginationTrue = false;
            var pagActCust = false,
                pagActCustCol;
            var pagInactCust = false,
                pagInactCustCol;
            if ($this.hasClass('pc_pag_1') || $this.hasClass('mob_pag_1')) {
              if ($this.attr('data-pag-active')) {
                pagActCust = true;
                pagActCustCol = $this.data('pag-active');
              }
              if ($this.attr('data-pag-inactive')) {
                pagInactCust = true;
                pagInactCustCol = $this.data('pag-inactive');
              }
              paginationTrue = true;
              pagCount = slideTotal;
              var thisContainer = $this.find('.basic_infinite_carouselContainer');
              $('<div class="basic_infinite_carousel_pagination"></div>').insertAfter(thisContainer);
              var pagTarget = $this.find('.basic_infinite_carousel_pagination');
              for (var cnt = 1; cnt <= pagCount; cnt++) {
                pagTarget.append('<div class="basic_infinite_carousel_pagination_dot"></div>');
              }
              pagTarget.find('.basic_infinite_carousel_pagination_dot').first().addClass('selected');
              if (pagActCust) {
                pagTarget.find('.basic_infinite_carousel_pagination_dot').first().css('background-color', pagActCustCol);
              }
              if (pagInactCust) {
                pagTarget.find('.basic_infinite_carousel_pagination_dot:not(:first)').css('background-color', pagInactCustCol);
              }
              pagTarget.find('.basic_infinite_carousel_pagination_dot').each(function(i) {
                $(this).addClass('basic_infinite_carousel_pagination_dot_' + (i + 1));
                if (i + 1 === pagCount) {
                  pagTarget.addClass('basic_infinite_carousel_pagination_complete');
                  $(document).trigger('basic_infinite_carousel_pagination_complete', $(this));
                }
              });
            }
            /*
            /--------------------------------------------------------------------------
            / Add identifying classes to the slides.
            /--------------------------------------------------------------------------
            */
            $this.find('.basic_infinite_carouselInner .basic_infinite_carousel_slide').each(function(i) {
              $(this).addClass('basic_infinite_carousel_slide_' + (i + 1));
            });

            /*
            /--------------------------------------------------------------------------
            / Handle the placeholder slides.
            /--------------------------------------------------------------------------
            */
            var firstSlideLoc = $this.find('.basic_infinite_carouselInner .basic_infinite_carousel_slide').first();
            var lastSlideLoc = $this.find('.basic_infinite_carouselInner .basic_infinite_carousel_slide').last();
            var firstSlide = firstSlideLoc.html();
            var lastSlide = lastSlideLoc.html();

            if (firstSlide.length || lastSlide.length) {
              $this.find('.basic_infinite_carousel_holder').first().append(lastSlide);
              $this.find('.basic_infinite_carousel_holder').last().append(firstSlide);
            }
            firstSlideLoc.addClass('basic_infinite_carousel_slide_first active');
            lastSlideLoc.addClass('basic_infinite_carousel_slide_last');

            /*
            /--------------------------------------------------------------------------
            / Calculate the slide animation value.
            /--------------------------------------------------------------------------
            */
            var isAnimating, pagAnimating, goingLeft, goingRight;
            isAnimating = pagAnimating = goingLeft = goingRight = false;
            var theDirection, pagDirection;
            var counter = 1;
            var pagCounter = -1;
            var pagCountNum = 1;
            var slideDivide = slideTotal / slideTotal;
            var resetRight = slideTotal + 1;
            var resetLeft = slideDivide - 1;
            var counterRight = slideDivide;
            var counterLeft = slideTotal;
            var marginResetLeft = -(slideTotal * 100) + '%';
            var marginResetRight = -(slideDivide * 100) + '%';
            var chevronVal = $this.find('.basic_infinite_carouselnav').length;

            /*
            /--------------------------------------------------------------------------
            / Handle the Carousel animation and counter.
            /--------------------------------------------------------------------------
            / Add functions to add/remove active class.
            / Add function to switch the first/last active class.
            / Handle the pagination dots.
            / Handle the carousel animation and counters.
            / Handle rtl.
            */

            var activeNext = function() {
              $this.find('.basic_infinite_carousel_slide.active').next().addClass('active').siblings().removeClass('active');
            };

            var activePrev = function() {
              $this.find('.basic_infinite_carousel_slide.active').prev().addClass('active').siblings().removeClass('active');
            };

            var switchActive = function(slide1, slide2) {
              $this.find('.basic_infinite_carousel_slide_' + slide1 + '.active').siblings('.basic_infinite_carousel_slide_' + slide2).addClass('active').siblings().removeClass('active');
            };

            // Determine direction (RTL support)
            var isRTL = false;
            setTimeout(function() {
              if ($('.field-content').hasClass('pcl_rtl')) {
                isRTL = true;
              }
            }, 1000);

            var negDirection = function() {
              theDirection = '-=100%';
              counter++;
              pagCountNum++;
              goingRight = true;
              goingLeft = false;
            };

            var posDirection = function() {
              theDirection = '+=100%';
              counter--;
              pagCountNum--;
              goingRight = false;
              goingLeft = true;
            };

            // Timings
            var easing = 'easeOutCirc';
            var willAutoScroll = false;
            if ($this.hasClass('auto_scroll_1')) {
              willAutoScroll = true;
              $this.removeClass('auto_scroll_1');
            } else if ($this.hasClass('auto_scroll_0')) {
              $this.removeClass('auto_scroll_0');
            }

            /*
            /--------------------------------------------------------------------------
            / Carousel Animation - Timer.
            /--------------------------------------------------------------------------
            / Set up clear/reset timer as click events to access it from
            / other templates, if required.
            */
            var clearThisTimer = $this.find('.basic_infinite_carousel_clear');
            var resetThisTimer = $this.find('.basic_infinite_carousel_reset');
            clearThisTimer.on('click', function(e) {
              e.stopPropagation();
              if (willAutoScroll) {
                clearInterval(interval);
              }
            });
            resetThisTimer.on('click', function(e) {
              e.stopPropagation();
              if (willAutoScroll) {
                resetTimer();
              }
            });

            /*
            /--------------------------------------------------------------------------
            / Carousel Animation - Pagination driven.
            /--------------------------------------------------------------------------
            */
            if (paginationTrue) {
              var animatePagination = function(pagCountNum) {
                $this.find('.basic_infinite_carousel_pagination_dot_' + pagCountNum).on('click', function() {
                  if ($(this).hasClass('selected')) {
                    pagAnimating = false;
                    return;
                  } else {
                    if (pagAnimating === false) {
                      clearThisTimer.trigger('click');
                      pagAnimating = true;
                      if (pagActCust && !pagInactCust) {
                        $(this).addClass('selected').css('background-color', pagActCustCol).siblings().removeClass('selected').css('background-color', '');
                      } else if (pagActCust && pagInactCust) {
                        $(this).addClass('selected').css('background-color', pagActCustCol).siblings().removeClass('selected').css('background-color', pagInactCustCol);
                      } else if (pagInactCust && !pagActCust) {
                        $(this).addClass('selected').css('background-color', '').siblings().removeClass('selected').css('background-color', pagInactCustCol);
                      } else {
                        $(this).addClass('selected').siblings().removeClass('selected');
                      }
                      pagDirection = (-pagCountNum * 100) + '%';
                      carouselWrapper.animate({
                        marginLeft: pagDirection
                      }, 700, easing).promise().done(function() {
                        counter = pagCountNum;
                        pagAnimating = false;
                        $this.find('.basic_infinite_carousel_slide_' + counter).addClass('active').siblings().removeClass('active'),
                        resetThisTimer.trigger('click');
                      });
                    }
                  }
                });
              };
              for (var count = 1; count <= pagCount; count++) {
                animatePagination(count);
              }
            }

            /*
            /--------------------------------------------------------------------------
            / Carousel Animation - Chevron driven.
            /--------------------------------------------------------------------------
            */
            var animateCarousel = function(arrowNo) {
              $this.find('.basic_infinite_carouselnav_' + arrowNo).on('click', function(e) {
                e.stopPropagation();
                if (isAnimating === false) {
                  clearThisTimer.trigger('click');
                  isAnimating = true;
                  if (arrowNo === 1) {
                    if (isRTL) {
                      negDirection();
                    } else {
                      posDirection();
                    }
                  } else {
                    if (isRTL) {
                      posDirection();
                    } else {
                      negDirection();
                    }
                  }
                  carouselWrapper.animate({
                    marginLeft: theDirection
                  }, 700, easing, function() {
                    if (goingRight) {
                      activeNext();
                    } else if (goingLeft) {
                      activePrev();
                    }
                    if (counter === resetRight) {
                      carouselWrapper.css({
                        'margin-left': marginResetRight
                      });
                      counter = counterRight;
                      switchActive('last', 'first');
                    } else if (counter === resetLeft) {
                      carouselWrapper.css({
                        'margin-left': marginResetLeft
                      });
                      counter = counterLeft;
                      switchActive('first', 'last');
                    }
                  }).promise().done(function() {
                    pagCountNum = counter;
                    if (pagActCust && !pagInactCust) {
                      $this.find('.basic_infinite_carousel_pagination_dot_' + counter).addClass('selected').css('background-color', pagActCustCol).siblings().removeClass('selected').css('background-color', '');
                    } else if (pagActCust && pagInactCust) {
                      $this.find('.basic_infinite_carousel_pagination_dot_' + counter).addClass('selected').css('background-color', pagActCustCol).siblings().removeClass('selected').css('background-color', pagInactCustCol);
                    } else if (pagInactCust && !pagActCust) {
                      $this.find('.basic_infinite_carousel_pagination_dot_' + counter).addClass('selected').css('background-color', '').siblings().removeClass('selected').css('background-color', pagInactCustCol);
                    } else {
                      $this.find('.basic_infinite_carousel_pagination_dot_' + counter).addClass('selected').siblings().removeClass('selected');
                    }
                    isAnimating = false;
                    resetThisTimer.trigger('click');
                  });
                }
              });
            };
            for (var i = 1; i <= chevronVal; i++) {
              animateCarousel(i);
            }

            /*
            /--------------------------------------------------------------------------
            / Set the carousel to automatically swipe if set by user.
            /--------------------------------------------------------------------------
            / Every x seconds the carousel swipes.
            */
            if (willAutoScroll) {
              var autoScrollDur = 6000;
              resetTimer = function() {
                interval = setInterval(function() {
                  $this.find('.basic_infinite_carouselnav_2').trigger('click');
                }, autoScrollDur);
              };
              resetThisTimer.trigger('click');
            }

            /*
            /--------------------------------------------------------------------------
            / Handle the Carousel animation for mobile.
            /--------------------------------------------------------------------------
            */
            var swipeEvent = function() {
              if ($(window).width() <= 768) {
                $this.find('.basic_infinite_carouselContainer').swiperight(function() {
                  $this.find('.basic_infinite_carouselnav_1').trigger('click');
                });
                $this.find('.basic_infinite_carouselContainer').swipeleft(function() {
                  $this.find('.basic_infinite_carouselnav_2').trigger('click');
                });
              } else {
                $this.find('.basic_infinite_carouselContainer').off('swiperight swipeleft');
                return;
              }
            };

            var resizeTimer;
            $(window).on('resize', function() {
              // Throttle/ debounce resize event:
              clearTimeout(resizeTimer);
              resizeTimer = setTimeout(function() {
                // Resizing has "stopped"
                swipeEvent();
              }, 10);
            });
            swipeEvent();
          } else {
            $this.find('.basic_infinite_carouselnav').children('.basic_infinite_carousel_slide').length ?
              $this.parent('.basic_infinite_carouselOuter').addClass('basic_infinite_carousel_headerReset') :
              $this.find('.basic_infinite_carouselnav').addClass('carousel_elem_hide');
          }
          /*
          /--------------------------------------------------------------------------
          / Remove margin bottom for mobile MPP hero carousel. Check if on SD MPP, on mobile, on a page with breadcrumbs(MPP) and if the carousel has a length.
          /--------------------------------------------------------------------------
          */
          if ($(window).width() <= 767 && $('body').find('.sd-product-grid').length > 0 && $('body').find('.js-breadcrumbs-v1').length > 0 && $('body').find('.basic_infinite_carousel_v1.mob_pag_0 .basic_infinite_carouselWrapper').length > 0) {
            $('.basic_infinite_carousel_v1.mob_pag_0 .basic_infinite_carouselWrapper').css('margin-bottom', '0');
          }
        });
      });
    }
  };
})(jQuery);
